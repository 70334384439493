const hamburger = () => {
    const hamburger = document.querySelector('.hamburger'),
    hamburgerItem = hamburger.querySelectorAll('.hamburger__item'),
    menu = document.querySelector('.menu-mobile'),
    menuItems = document.querySelectorAll('.menu__link'),
    header = document.querySelector('.header'),
    modal = document.querySelector('.modal_menu'),
    logo = document.querySelector('.header__logo-wrapper'),
    
    classes = {
        menu: 'menu-mobile_active',
        wrap: 'menu__wrapper_active',
        hide: 'hide',
        left: 'hamburger__item_left',
        right: 'hamburger__item_right'
    };

    menuItems.forEach(i => {
        i.addEventListener('click', (e) => {
            menu.classList.remove('menu-mobile_active');
            header.classList.remove('header_active');
            hamburgerItem[2].classList.remove('hide');
            hamburgerItem[1].classList.remove('hamburger__item_left');
            hamburgerItem[0].classList.remove('hamburger__item_right');
            modal.classList.remove('modal_menu_active');
        });
    });
    hamburger.addEventListener('click', (e) => {
        if (menu.classList.contains('menu-mobile_active')) {
            menu.classList.remove('menu-mobile_active');
            header.classList.remove('header_active');
            hamburgerItem[2].classList.remove('hide');
            hamburgerItem[1].classList.remove('hamburger__item_left');
            hamburgerItem[0].classList.remove('hamburger__item_right');
            modal.classList.remove('modal_menu_active');

           
        } else {
            menu.classList.add('menu-mobile_active');
            header.classList.add('header_active');
            hamburgerItem[2].classList.add('hide');
            hamburgerItem[1].classList.add('hamburger__item_left');
            hamburgerItem[0].classList.add('hamburger__item_right');
            modal.classList.add('modal_menu_active');
            document.body.overflow = 'hidden';
        }
    });
};

export default hamburger;